import * as FontAwesome from "react-fontawesome";
import * as MXTS from "@maxxton/cms-mxts-api";
import * as React from "react";
import * as classNames from "classnames";

import { AccommodationType, Resort, Unit } from "../../mxts";
import { Card, CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import { ConfiguredLink, getUrlWithAnchor } from "../../../utils/linking.util";
import { Content, getContent } from "../../../utils/content.util";
import { DispatchOpenLinkedTabOptions, getDynamicBookLink, setPageViewEvent } from "../resultsPanelUtil";
import { DynamicFieldLink, withDynamicField } from "../../../utils/withDynamicField";
import RatingStars, { Iconstyles } from "../../../components/RatingStars";
import { UrlLinkParams, UrlParamsUtil } from "../../../utils/urlparam.util";
import { connect, useDispatch, useSelector } from "react-redux";
import { getI18nLocaleString, wrapProps } from "../../../i18n";
import { getNoDataFoundTemplate, setOpacityOnHide } from "../../../components/utils";

import { AvailabilityState } from "../../../redux/reducers/availability.types";
import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { DynamicWidgetBaseProps } from "../../dynamic/dynamicWidget.types";
import LocalizedTitleAndLabel from "../../../components/widgetTitleAndLabel/LocalizedLableTitle";
import { MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { ReviewRatingFallback } from "../../../components/ReviewRatingFallback";
import { SmartLink } from "../../../components/SmartLink";
import { State } from "../../../redux";
import { WidgetOptions } from "./";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import namespaceList from "../../../i18n/namespaceList";
import { renderNoResultsFoundContent } from "../../dynamic/containerWidget.util";
import { updateMaxRating } from "../../../redux/actions/userInterfaceAction";

interface ReviewRatingStoreProps {
    dynamicFilter: DynamicFilter;
    myEnvState: MyEnvState;
    availabilityState: AvailabilityState;
}

interface ReviewRatingWidgetBaseProps extends DynamicWidgetBaseProps<WidgetOptions> {
    resort?: MXTS.Resort;
    accommodationType?: AccommodationType;
    unit?: Unit;
    unitBookUri?: string;
    amenityCodes?: string[];
    dispatchOpenLinkedTabAction?: (options: DispatchOpenLinkedTabOptions) => void;
    link: ConfiguredLink;
    dynamicFieldLink?: DynamicFieldLink;
    getDynamicFieldLink?: (content: Content, usedForLinking?: boolean) => Promise<void>;
}

interface AdditionalChildProps {
    accommodationType?: AccommodationType;
    unit?: Unit;
    resort?: Resort;
    dynamicFilter?: DynamicFilter;
    notifyParentNoDynamicContentWasFound?: (ischildPresent: boolean) => void;
}

interface ReviewRatingProps extends ReviewRatingWidgetBaseProps, ReviewRatingStoreProps {}

// eslint-disable-next-line max-lines-per-function
const ReviewRatingsWidgetBase = (props: ReviewRatingProps) => {
    const { options, context, unitBookUri, dynamicFilter, amenityCodes, className, dispatchOpenLinkedTabAction, isMyEnvWidget, link, getDynamicFieldLink, dynamicFieldLink } = props;
    const tabLinkClass = options?.tabLink ? "cursor-pointer" : "";
    const [content, setContent] = React.useState<Content | Content[]>();
    const [dynamicBookUrl, setdynamicBookUrl] = React.useState<string>();
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [template, setTemplate] = React.useState<JSX.Element[] | null>(null);
    const [isChildDisabled, setisChildDisabled] = React.useState<boolean>(false);

    const isUnitType = (content as Unit)?.unitId;
    const isAccommodationType = (content as AccommodationType)?.resourceId && !isUnitType;
    const isResortType = (content as Resort)?.resortId;
    const accommodationType = isAccommodationType ? (content as AccommodationType) : undefined;
    const { selectedReservation } = useSelector((state: State) => (isMyEnvWidget ? state.myEnvState : {}));
    const unit = isUnitType ? (content as Unit) : undefined;
    const resort = isResortType ? (content as Resort) : undefined;
    const bookUrl = UrlParamsUtil.getBookingsEngineUrl(context.currentLocale.code, unit, accommodationType, unitBookUri, dynamicFilter, amenityCodes);
    const resortRating = (resort?.accommodationTypes?.length && resort.accommodationTypes[0].resortRating) || 0;
    const { currentLocale, site } = context;
    const hideWidget = setOpacityOnHide(options);
    const localizedWidgetTitle: string = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedWidgetTitle || [], keys: ["widgetTitleText"] })?.widgetTitleText || "";
    const titleOptions = {
        localizedTitle: localizedWidgetTitle,
        enableWidgetTitle: options.enableWidgetTitle,
        useTitleHeadings: options.useTitleHeadings,
        styleWidgetTitle: options.styleWidgetTitle,
        className: classNames("widget-heading", `${options.fontColor?.includes("theme") ? `color-${options.fontColor}` : ""}`),
        style: options.fontColor?.includes("rgba") ? options.fontColor : "",
    };
    const loadContent = React.useCallback(async () => {
        const content = await getContent(props);
        setContent(content);
    }, [selectedReservation]);

    const dispatch = useDispatch();
    const maxRatingState = useSelector((state: State) => state.userInterfaceState.reviewRatingsWidget?.maxRating);

    React.useEffect(() => {
        loadContent();
        const params: UrlLinkParams = dynamicFilter ? UrlParamsUtil.getUrlParamsFromFilter(dynamicFilter) : {};
        const resourceId =
            Array.isArray(dynamicFilter.resourceid) && dynamicFilter.resourceid.length === 1
                ? dynamicFilter.resourceid[0]
                : !Array.isArray(dynamicFilter.resourceid)
                ? dynamicFilter.resourceid
                : undefined;
        const addResourceIdParams = resourceId || accommodationType?.resourceId || (content as Unit)?.resourceId || (resort as Resort)?.curatedAccommodation?.resourceId;
        const addUnitIdParams = dynamicFilter?.unitid || (content as Unit)?.unitId;
        const dynamicBookUrl = getDynamicBookLink(params, link, addResourceIdParams, addUnitIdParams);
        setdynamicBookUrl(dynamicBookUrl);

        if (options?.linking?.useDynamicFieldAsLink && !dynamicFieldLink && getDynamicFieldLink && content) {
            getDynamicFieldLink(content as Content, true);
        }
    }, [loadContent, content]);

    React.useEffect(() => {
        if (isAccommodationType && (content as AccommodationType).resourceRating > 0) {
            const accommodationType = content as AccommodationType;
            if (accommodationType.resourceRating > maxRatingState!) {
                dispatch(updateMaxRating(accommodationType.resourceRating));
            }
        } else if (isUnitType && (content as Unit).unitRating > 0) {
            const unit = content as Unit;
            if (unit.unitRating > maxRatingState!) {
                dispatch(updateMaxRating(unit.unitRating));
            }
        } else if (isResortType && resortRating > 0) {
            if (resortRating > maxRatingState!) {
                dispatch(updateMaxRating(resortRating));
            }
        }
    }, [accommodationType, maxRatingState, content, resortRating]);

    const callSetViewEvent = () => {
        setPageViewEvent(context, bookUrl, accommodationType, unit);
    };
    const handleDispatchOpenLinkedTabAction = async () => {
        if (options.reviewPopup && options.templateId) {
            const getTemplate = await getNoDataFoundTemplate(options.templateId, props.context);
            // NOte:- It will send these props to child (mainly implemented for guest review/rating widget) so that the child (guest review/rating widget) will be visible on popup.
            const additionalChildprops: AdditionalChildProps = {
                accommodationType,
                unit,
                resort,
                dynamicFilter,
            };
            if (options.useDynamicContent) {
                additionalChildprops.notifyParentNoDynamicContentWasFound = addChildWithoutContent;
            }
            const childrenWithProps = React.Children.map(getTemplate, (child) => React.cloneElement((child as React.ReactElement<any>).props.children, additionalChildprops));
            setTemplate(childrenWithProps);
            setOpenModal(!openModal);
        }
        if (typeof dispatchOpenLinkedTabAction === "function") {
            dispatchOpenLinkedTabAction(options);
        }
    };

    const addChildWithoutContent = (ischildPresent: boolean) => {
        setisChildDisabled(ischildPresent);
    };
    // jscpd:ignore-start
    const dynamicFontStyleClass = `${options.descFontColor?.includes("theme") ? `color-${options.descFontColor}` : ""}`;
    const styles: any = {
        color: options.descFontColor?.includes("rgba") && options.reviewStyle ? options.descFontColor : undefined,
    };
    const iconstyles: Iconstyles = {
        fill: options.ratingIconColor?.includes("rgba") ? options.ratingIconColor : undefined,
        stroke: options.ratingIconColor?.includes("rgba") ? options.ratingIconColor : undefined,
        stopColor: options.ratingIconColor?.includes("rgba") ? options.ratingIconColor : undefined,
    };
    const linkUrl = options?.linking?.useDynamicFieldAsLink ? dynamicFieldLink?.url : (options.useAsDynamicBookUrlLink && dynamicBookUrl) || getUrlWithAnchor(link);
    // jscpd:ignore-end
    if (options.displayType && content) {
        if (options.displayType === "review") {
            if (isAccommodationType) {
                return (
                    <div className={`${hideWidget}`}>
                        <LocalizedTitleAndLabel {...titleOptions} />
                        {options.showNotEnoughRatingsText && (content as AccommodationType).numberOfReviews < 4 ? (
                            <ReviewRatingFallback reviewProps={props} />
                        ) : linkUrl ? (
                            <SmartLink href={linkUrl} target={link.target}>
                                <div className={`type-review-value ${tabLinkClass} ${options.descFontSize || ""} `} style={styles} onClick={handleDispatchOpenLinkedTabAction}>
                                    <span className={"type-review-value__amount"}>{`${(content as AccommodationType).numberOfReviews}`}</span>
                                    <span className={"type-review-value__text"}> {`${getI18nLocaleString(namespaceList.widgetResultsReviewRating, "reviewText", currentLocale, site)}`}</span>
                                </div>
                            </SmartLink>
                        ) : (
                            <div className={`type-review-value ${tabLinkClass} ${options.descFontSize || ""} `} style={styles} onClick={handleDispatchOpenLinkedTabAction}>
                                <span className={"type-review-value__amount"}>{`${(content as AccommodationType).numberOfReviews}`}</span>
                                <span className={"type-review-value__text"}> {`${getI18nLocaleString(namespaceList.widgetResultsReviewRating, "reviewText", currentLocale, site)}`}</span>
                            </div>
                        )}
                        {openModal && !isChildDisabled && renderModal(openModal, setOpenModal, template, context)}
                    </div>
                );
            } else if (isUnitType) {
                return (
                    <div className={`${hideWidget}`}>
                        <LocalizedTitleAndLabel {...titleOptions} />
                        {options.showNotEnoughRatingsText && (content as Unit).nrOfUnitReviews < 4 ? (
                            <ReviewRatingFallback reviewProps={props} />
                        ) : (content as Unit).nrOfUnitReviews !== 0 ? (
                            linkUrl ? (
                                <SmartLink href={linkUrl} target={link.target}>
                                    <div className={`unit-review-value ${tabLinkClass} ${options.descFontSize || ""}`} style={styles} onClick={handleDispatchOpenLinkedTabAction}>
                                        {(content as Unit).nrOfUnitReviews}
                                    </div>
                                </SmartLink>
                            ) : (
                                <div className={`unit-review-value ${tabLinkClass} ${options.descFontSize || ""}`} style={styles} onClick={handleDispatchOpenLinkedTabAction}>
                                    {(content as Unit).nrOfUnitReviews}
                                </div>
                            )
                        ) : null}
                        {openModal && !isChildDisabled && renderModal(openModal, setOpenModal, template, context)}
                    </div>
                );
            } else if (isResortType) {
                return (
                    <div className={`${hideWidget}`}>
                        <LocalizedTitleAndLabel {...titleOptions} />
                        {options.showNotEnoughRatingsText && resort?.accommodationTypes?.length && resort.accommodationTypes[0].numberOfReviews < 4 ? (
                            <ReviewRatingFallback reviewProps={props} />
                        ) : linkUrl ? (
                            <SmartLink href={linkUrl} target={link.target}>
                                <div className={classNames("resort-review-value", tabLinkClass, options.descFontSize)} style={styles} onClick={handleDispatchOpenLinkedTabAction}>
                                    {`${resort?.accommodationTypes?.length && resort.accommodationTypes[0].numberOfReviews} ${getI18nLocaleString(
                                        namespaceList.widgetResultsReviewRating,
                                        "reviewText",
                                        currentLocale,
                                        site
                                    )}`}
                                </div>
                            </SmartLink>
                        ) : (
                            <div className={classNames("resort-review-value", tabLinkClass, options.descFontSize)} style={styles} onClick={handleDispatchOpenLinkedTabAction}>
                                {`${resort?.accommodationTypes?.length && resort.accommodationTypes[0].numberOfReviews} ${getI18nLocaleString(
                                    namespaceList.widgetResultsReviewRating,
                                    "reviewText",
                                    currentLocale,
                                    site
                                )}`}
                            </div>
                        )}
                        {openModal && !isChildDisabled && renderModal(openModal, setOpenModal, template, context)}
                    </div>
                );
            }
        } else if (options.displayType === "rating") {
            if (isAccommodationType && (content as AccommodationType).resourceRating > 0) {
                const accommodationType = content as AccommodationType;
                return (
                    <div className={`${hideWidget}`}>
                        <LocalizedTitleAndLabel {...titleOptions} />
                        <div className={`type-rating ${tabLinkClass}`} onClick={handleDispatchOpenLinkedTabAction}>
                            {options.showNotEnoughRatingsText && accommodationType.numberOfReviews < 4 ? (
                                <ReviewRatingFallback reviewProps={props} />
                            ) : options.enableBookingsEngineLink ? (
                                <SmartLink href={bookUrl} target="_blank" rel="noreferrer" onClick={callSetViewEvent}>
                                    <RatingStars
                                        className="space-mr-xxs sx"
                                        rating={accommodationType.resourceRating}
                                        iconstyles={options.ratingStyle ? iconstyles : undefined}
                                        ratingIconSize={options.ratingStyle ? options.ratingIconSize : undefined}
                                    />
                                    {options.displayRatingValue ? (
                                        <span className={`type-rating-value ${className} ${options.descFontSize || ""} ${dynamicFontStyleClass}`} style={styles}>
                                            {accommodationType.resourceRating}
                                        </span>
                                    ) : null}
                                </SmartLink>
                            ) : linkUrl ? (
                                <SmartLink href={linkUrl} target={link.target}>
                                    {reviewRatingContent("type-rating__inner", accommodationType.resourceRating, options, iconstyles, styles, dynamicFontStyleClass, tabLinkClass, className)}
                                </SmartLink>
                            ) : (
                                reviewRatingContent("type-rating__inner", accommodationType.resourceRating, options, iconstyles, styles, dynamicFontStyleClass, tabLinkClass, className)
                            )}
                        </div>
                        {openModal && !isChildDisabled && renderModal(openModal, setOpenModal, template, context)}
                    </div>
                );
            } else if (isUnitType && (content as Unit).unitRating > 0) {
                const unit = content as Unit;
                return (
                    <div className={`${hideWidget}`}>
                        <LocalizedTitleAndLabel {...titleOptions} />
                        <div className={`unit-rating ${tabLinkClass} ${className}`} onClick={handleDispatchOpenLinkedTabAction}>
                            {options.showNotEnoughRatingsText && unit.nrOfUnitReviews < 4 ? (
                                <ReviewRatingFallback reviewProps={props} />
                            ) : options.enableBookingsEngineLink ? (
                                <SmartLink href={bookUrl} target="_blank" rel="noreferrer" onClick={callSetViewEvent}>
                                    <RatingStars
                                        className="space-mr-xxs"
                                        rating={unit.unitRating}
                                        iconstyles={options.ratingStyle ? iconstyles : undefined}
                                        ratingIconSize={options.ratingStyle ? options.ratingIconSize : undefined}
                                    />
                                    {options.displayRatingValue ? (
                                        <span className={`unit-rating-value ${className} ${options.descFontSize || ""} ${dynamicFontStyleClass}`} style={styles}>
                                            {unit.unitRating}
                                        </span>
                                    ) : null}
                                </SmartLink>
                            ) : linkUrl ? (
                                <SmartLink href={linkUrl} target={link.target}>
                                    {reviewRatingContent("unit-rating__inner", unit.unitRating, options, iconstyles, styles, dynamicFontStyleClass)}
                                </SmartLink>
                            ) : (
                                reviewRatingContent("unit-rating__inner", unit.unitRating, options, iconstyles, styles, dynamicFontStyleClass)
                            )}
                        </div>
                        {openModal && !isChildDisabled && renderModal(openModal, setOpenModal, template, context)}
                    </div>
                );
            } else if (isResortType && resortRating > 0) {
                return (
                    <div className={`${hideWidget}`}>
                        <LocalizedTitleAndLabel {...titleOptions} />
                        <div className={`type-rating ${tabLinkClass}`} onClick={handleDispatchOpenLinkedTabAction}>
                            {options.showNotEnoughRatingsText && resort?.accommodationTypes?.length && resort.accommodationTypes[0].numberOfReviews < 4 ? (
                                <ReviewRatingFallback reviewProps={props} />
                            ) : linkUrl ? (
                                <SmartLink href={linkUrl} target={link.target}>
                                    {reviewRatingContent("type-rating__inner", resortRating, options, iconstyles, styles, dynamicFontStyleClass, tabLinkClass, className)}
                                </SmartLink>
                            ) : (
                                reviewRatingContent("type-rating__inner", resortRating, options, iconstyles, styles, dynamicFontStyleClass, tabLinkClass, className)
                            )}
                        </div>
                        {openModal && !isChildDisabled && renderModal(openModal, setOpenModal, template, context)}
                    </div>
                );
            }
        }
    }
    return null;
};

function renderModal(openModal: boolean, setOpenModal: React.Dispatch<React.SetStateAction<boolean>>, template: JSX.Element[] | null, context: CMSProviderProperties) {
    return (
        <Modal isOpen={openModal} toggle={() => setOpenModal(!openModal)} className={classNames("modal-review-rating")}>
            <ModalHeader tag="div">
                <button aria-hidden="true" onClick={() => setOpenModal(!openModal)}>
                    <FontAwesome name="close" />
                </button>
            </ModalHeader>
            <ModalBody>
                <Card>
                    <CardBody>{renderNoResultsFoundContent({ noResultsFoundWebContent: null, noResultsFoundTemplate: template, context })}</CardBody>
                </Card>
            </ModalBody>
        </Modal>
    );
}

function reviewRatingContent(classname: string, rating: number, options: WidgetOptions, iconstyles: Iconstyles, styles: any, dynamicFontStyleClass: string, tabLinkClass?: string, className?: string) {
    return (
        <div className={classname === "type-rating__inner" ? `type-rating__inner ${tabLinkClass}` : classname}>
            <RatingStars
                className={classname === "type-rating__inner" ? `space-mr-xxs sx ${className}` : "space-mr-xxs"}
                rating={rating}
                iconstyles={options.ratingStyle ? iconstyles : undefined}
                ratingIconSize={options.ratingStyle ? options.ratingIconSize : undefined}
            />
            {options.displayRatingValue ? (
                <span
                    className={
                        classname === "type-rating__inner"
                            ? `type-rating-value small-font-size ${className} ${options.descFontSize || ""} ${dynamicFontStyleClass}`
                            : `unit-rating-value ${className} ${options.descFontSize || ""} ${dynamicFontStyleClass}`
                    }
                    style={styles}
                >
                    {rating}
                </span>
            ) : null}
        </div>
    );
}

function mapStateToProps(state: State): ReviewRatingStoreProps {
    return {
        myEnvState: state.myEnvState,
        dynamicFilter: state.dynamicFilter,
        availabilityState: state.availabilityState,
    };
}

export const ReviewRatingsWidget = withDynamicField(wrapProps<ReviewRatingWidgetBaseProps>(connect<ReviewRatingStoreProps>(mapStateToProps)(ReviewRatingsWidgetBase)));
