import * as React from "react";

import { ConfiguredLink, getLinkFromLinkingSpec } from "../../../utils/linking.util";
import { FormSpec, multiSelectStylePicker, templateSpec } from "../../../form-specs";
import { LinkingSpecOptions, linkingSpec } from "../../../inputSpecs/linkingSpec";
import { LocalizedTitleOptions, getWidgetTitleOptions, titleStylingOptions } from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";

import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { ReviewRatingsWidget } from "./ReviewRatings";
import { TypesearchContainerWidgetSpec } from "../../";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getContentTypeSelector } from "../../../components/utils";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";
import tabExtraReviewAutocomplete from "../../../inputSpecs/tabExtraReviewAutocomplete";
import { tabLinkAutocomplete } from "../../../inputSpecs";

export interface WidgetOptions extends LocalizedTitleOptions {
    displayType: string;
    styleIds: string[];
    displayRatingValue: boolean;
    enableBookingsEngineLink: boolean;
    tabLink?: string;
    contentTypeSelector?: boolean;
    contentType?: string;
    resortMultiSelector?: NumberMultiSelectOption[];
    resourceId?: number;
    unitId?: number;
    toggleButton: boolean;
    ElementToToggle: string;
    descFontSize: string;
    descFontColor: string;
    ratingIconColor: string;
    ratingIconSize?: number;
    reviewStyle: boolean;
    ratingStyle: boolean;
    linking?: LinkingSpecOptions;
    useAsDynamicBookUrlLink: boolean;
    reviewPopup: boolean;
    templateId?: string | null;
    useDynamicContent: boolean;
    showNotEnoughRatingsText?: boolean;
    notEnoughRatingsText?: string | null;
    lessReviewWebContentId?: string | null;
    lessReviewTemplateId?: string | null;
    displayLessReviewInfo?: string;
}

const TARGETS = ["review-rating"];

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "results-panel-review-rating-widget",
    name: getI18nLocaleObject(namespaceList.widgetResultsReviewRating, "review-rating"),
    pluralName: getI18nLocaleObject(namespaceList.widgetResultsReviewRating, "reviews-ratings"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            ...getContentTypeSelector(),
                            {
                                variable: "displayType",
                                type: "select",
                                optionList: [
                                    {
                                        value: "review",
                                        label: getI18nLocaleObject(namespaceList.widgetResultsReviewRating, "reviews"),
                                    },
                                    {
                                        value: "rating",
                                        label: getI18nLocaleObject(namespaceList.widgetResultsReviewRating, "ratings"),
                                    },
                                ],
                                placeholder: getI18nLocaleObject(namespaceList.widgetResultsReviewRating, "reviewPlaceholder"),
                            },
                            {
                                variable: "displayRatingValue",
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.displayType === "rating",
                                label: getI18nLocaleObject(namespaceList.widgetResultsReviewRating, "displayRatingValue"),
                            },
                            {
                                variable: "enableBookingsEngineLink",
                                label: getI18nLocaleObject(namespaceList.dynamicPlugin, "enableBookingsEngineLink"),
                                type: "checkbox",
                            },
                            {
                                variable: "toggleButton",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "toggleButton"),
                                type: "checkbox",
                            },
                            {
                                variable: "ElementToToggle",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "ElementToToggle"),
                                type: "text",
                                visible: (options: WidgetOptions): boolean => options.toggleButton,
                            },
                            ...getWidgetTitleOptions<WidgetOptions>(),
                        ],
                    ],
                },

                {
                    name: getI18nLocaleObject(namespaceList.admin, "linking"),
                    properties: [
                        [tabLinkAutocomplete("tabLink")],
                        [
                            {
                                variable: "useAsDynamicBookUrlLink",
                                label: getI18nLocaleObject(namespaceList.admin, "useAsDynamicBookUrlLink"),
                                type: "checkbox",
                            },
                            linkingSpec<WidgetOptions, keyof WidgetOptions>({ variable: "linking" }, true),
                        ],
                    ],
                },
                // jscpd:ignore-start
                {
                    name: getI18nLocaleObject(namespaceList.admin, "styling"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            {
                                variable: "ratingStyle",
                                label: getI18nLocaleObject(namespaceList.widgetResultsReviewRating, "ratingStyle"),
                                type: "checkbox",
                            },
                            {
                                variable: "ratingIconColor",
                                label: getI18nLocaleObject(namespaceList.widgetResultsReviewRating, "ratingIconColor"),
                                type: "dual-color",
                                default: "icon-default",
                                visible: (options: WidgetOptions) => !!options.ratingStyle,
                            },
                            {
                                variable: "ratingIconSize",
                                label: getI18nLocaleObject(namespaceList.widgetResultsReviewRating, "ratingIconSize"),
                                type: "number",
                                visible: (options: WidgetOptions) => !!options.ratingStyle,
                            },
                            {
                                variable: "reviewStyle",
                                label: getI18nLocaleObject(namespaceList.widgetResultsReviewRating, "reviewStyle"),
                                type: "checkbox",
                            },
                            {
                                variable: "descFontSize",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "fontSize"),
                                type: "select",
                                default: "default-font-size",
                                visible: (options: WidgetOptions) => !!options.reviewStyle,
                                optionList: [
                                    {
                                        value: "default-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "defaultFontSize"),
                                    },
                                    {
                                        value: "micro-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "microFontSize"),
                                    },
                                    {
                                        value: "xx-small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "xxSmallFontSize"),
                                    },
                                    {
                                        value: "x-small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "xSmallFontSize"),
                                    },
                                    {
                                        value: "small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "smallFontSize"),
                                    },
                                    {
                                        value: "h6-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h6Fonts"),
                                    },
                                    {
                                        value: "h5-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h5Fonts"),
                                    },
                                    {
                                        value: "h4-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h4Fonts"),
                                    },
                                    {
                                        value: "h3-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h3Fonts"),
                                    },
                                    {
                                        value: "h2-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h2Fonts"),
                                    },
                                    {
                                        value: "h1-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h1Fonts"),
                                    },
                                ],
                            },
                            {
                                variable: "descFontColor",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "fontColor"),
                                type: "dual-color",
                                default: "font-default",
                                visible: (options: WidgetOptions) => !!options.reviewStyle,
                            },
                            // jscpd:ignore-end
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "titleParagraph"),
                                type: "paragraph",
                                visible: (options: WidgetOptions) => !!options.enableWidgetTitle,
                            },
                            ...titleStylingOptions<WidgetOptions>(),
                            {
                                variable: "reviewPopup",
                                label: getI18nLocaleObject(namespaceList.widgetResultsReviewRating, "reviewPopup"),
                                type: "checkbox",
                            },
                            {
                                variable: "templateId",
                                label: getI18nLocaleObject(namespaceList.widgetResultsReviewRating, "selectTemplate"),
                                type: "autocomplete",
                                refType: templateSpec,
                                placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundTemplatePlaceholder"),
                                visible: (options: WidgetOptions) => options.reviewPopup,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "useDynamicContent"),
                                variable: "useDynamicContent",
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.reviewPopup,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "extra"),
                    properties: [[...tabExtraReviewAutocomplete()]],
                },
            ],
        },
    ],
};

export const resultsPanelRatingsReviewWidget: TypesearchContainerWidgetSpec<WidgetOptions> = {
    id: "resultsPanelRatingsReviewWidget",
    type: "resultsPanel",
    widgetGroup: WidgetGroup ? WidgetGroup.DYNAMIC : 1,
    name: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "singleReviewRating"),
    description: getI18nLocaleObject(namespaceList.widgetResultsReviewRating, "reviewWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        displayType: "",
        styleIds: [],
        displayRatingValue: false,
        enableBookingsEngineLink: false,
        ratingStyle: false,
        toggleButton: false,
        reviewStyle: false,
        ElementToToggle: "",
        descFontSize: "default-font-size",
        descFontColor: "font-default",
        ratingIconColor: "icon-default",
        useAsDynamicBookUrlLink: false,
        reviewPopup: false,
        useDynamicContent: false,
        showNotEnoughRatingsText: false,
    }),
    container: false,
    async render(widget, context, sitemapWidgetOptions, resultOption, dynamicContainerOptions) {
        const styleClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, widget.options.styleIds);
        const { linking } = widget.options;
        const link: ConfiguredLink = await getLinkFromLinkingSpec({ linkingSpecOptions: linking, context });
        return <ReviewRatingsWidget dynamicContainerOptions={dynamicContainerOptions} options={widget.options} context={context} className={styleClasses} link={link} />;
    },
};
