import { SomeInputSpec, templateSpec } from "../form-specs";

import { getI18nLocaleObject } from "../i18n";
import namespaceList from "../i18n/namespaceList";
import { webcontentSpecWithoutPreview } from "../form-specs/models/autocompleteWebContent";

const tabExtraReviewAutocomplete = (): Array<SomeInputSpec<any, any>> => [
    {
        variable: "showNotEnoughRatingsText",
        label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "showNotEnoughRatingsText"),
        default: false,
        type: "checkbox",
    },
    {
        variable: "notEnoughRatingsText",
        label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "showNotEnoughRatingsText"),
        type: "text",
        visible: (options: any) => !!options.showNotEnoughRatingsText,
    },
    {
        variable: "lessReviewWebContentId",
        label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "lessReviewFoundWebcontent"),
        type: "autocomplete",
        refType: webcontentSpecWithoutPreview,
        placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundContentPlaceholder"),
        visible: (options: any) => !!options.showNotEnoughRatingsText,
    },
    {
        variable: "lessReviewTemplateId",
        label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "lessReviewFoundTemplate"),
        type: "autocomplete",
        refType: templateSpec,
        placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundTemplatePlaceholder"),
        visible: (options: any) => !!options.showNotEnoughRatingsText,
    },
    {
        variable: "displayLessReviewInfo",
        label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "displayLessReviewInfo"),
        type: "select",
        default: "viewAsInline",
        visible: (options: any) => !!options.showNotEnoughRatingsText,
        optionList: [
            {
                value: "viewAsInline",
                label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "viewAsInline"),
            },
            {
                value: "viewAsTooltip",
                label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "viewAsTooltip"),
            },
            {
                value: "viewAsModal",
                label: getI18nLocaleObject(namespaceList.admin, "viewAsModal"),
            },
        ],
    },
];

export default tabExtraReviewAutocomplete;
